import Airtable from 'airtable';
import React, { useState, useEffect } from 'react';
import ModelArticle from "./ModelArticle"
import "./Models.css"
import Tags from './Tags';

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

function Model(){
    const [records, setRecords] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        base('projects').select({ view: "Grid view" }).eachPage((records, fetchNextPage) => {
            setRecords(records);
            setData(records);
            fetchNextPage();
        }, (err) => {
            if (err) {
                console.error(err);
                return;
            }
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    const record_types = [...new Set(data.map((val)=> val.fields['type']).flat())]
    const record_labels = [...new Set(data.map((val)=> val.fields['label'].split(',')).flat())]
    const record_tags = record_types.concat(record_labels)
    
    const filterRecords = (tag_selected) => data.find(datum => datum.fields['type'] === tag_selected);
    // const filterRecords = (tag_selected) => {
    //     console.log(tag_selected)
    //     if (tag_selected !== null && tag_selected !== ''){
    //         const new_records = data.filter((new_rec) => new_rec.fields['type'] === tag_selected || new_rec.fields['label'] === tag_selected)
    //         // setRecords(new_records)
    //     }
    // }

    return(
        <div className="models">
            <div className="models__filters">
                <div className="models__filtersHeading">
                    <ul className="models__filterTagsCategories">
                        <li>
                            <button className="models__filterTagsButtons">Tasks</button>
                            <button className="models__filterTagsButtons">Libraries</button>
                            <button className="models__filterTagsButtons">Datasets</button>
                            <button className="models__filterTagsButtons">Langauge</button>
                            <button className="models__filterTagsButtons">Licenses</button>
                            <button className="models__filterTagsButtons">Other</button>
                            <button className="models__filterTagsButtons">Clear</button>
                        </li>
                    </ul>
                </div>
                <div className='models__filterTags'>
                    <Tags
                        tag_items={record_tags}
                        filter_records={filterRecords}
                        set_records={setRecords}
                    />
                </div>
            </div>
            <div className="models__results">
                <div className="models__resultsHeading">
                        <h3 className="models__text">Models</h3>
                        <input type="text" className="models__resultsSearchInput"/>
                        <ul className="models__filterResultTags">
                            <li>
                                <button className="models__filterTagResultButtons">Business Models </button>
                                <button className="models__filterTagResultButtons">Public Models</button>
                            </li>
                        </ul>
                </div>
                <div className="models__articleList">
                    {records.map(record => (
                        <ModelArticle 
                            key={record.id} 
                            title={record.fields['name']}
                            label={record.fields['label']}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Model