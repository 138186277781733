import React from "react";
import './Header.css'
import { Link } from "react-router-dom"

function Header(){
    return(
        <nav className="header">
            <div className="header__logoName">
                <img className="header__logo" alt="logo"/>
            </div>
            <div className="header__linksArea">
                <Link to="/" className="header__pageLink">
                    <div className="header__Link">
                        <span >Home</span>
                    </div>
                </Link>
                <Link to="/Models" className="header__pageLink">
                    <div className="header__Link">
                        <span >Models</span>
                    </div>
                </Link>
                <Link to="/LanguageModelForm" className="header__pageLink">
                    <div className="header__Link">
                        <span >LLMs Register</span>
                    </div>
                </Link>
                <Link to="/Register" className="header__pageLink">
                    <div className="header__Link">
                        <span >Register</span>
                    </div>
                </Link>
                <Link to="/Login" className="header__pageLink">
                    <div className="header__Link">
                        <span >Login</span>
                    </div>
                </Link>
            </div>
        </nav>
    )
}

export default Header