import "./Home.css";
import ModelPost from "./ModelPost"
import ServiceProduct from "./ServiceProduct"

function Home(){
    return(
        <div className="home">
            <div className="home__imageGroup">
                <img className="home__image" alt="AI marketplace image"
                    src="https://www.globality.com/hs-fs/hubfs/WhyAIPoweredMarketplace-Blogpost-06302022.png?width=2100&name=WhyAIPoweredMarketplace-Blogpost-06302022.png"/>
                <h1>Invest. Deploy. Train. Outsource. Sell.</h1>
                <div className="home__search">
                    <input type="text" className="home__searchInput"/>
                </div>
            </div>
            <div className="home__modelPosts">
                <h1>Latest Models</h1>
                <div className="home__modelPostsRow">
                    <ModelPost
                        id={1}
                        title="Llamaindex"
                        description="LlamaIndex is a simple, flexible data framework for connecting custom data sources to large language models."
                        type={"applied"}
                        label="Framework"
                        source="https://www.llamaindex.ai"
                    />
                    <ModelPost
                        id={1}
                        title="Llamaindex"
                        description="LlamaIndex is a simple, flexible data framework for connecting custom data sources to large language models."
                        type={"applied"}
                        label="Framework"
                        source="https://www.llamaindex.ai"
                    />
                    <ModelPost
                        id={1}
                        title="Llamaindex"
                        description="LlamaIndex is a simple, flexible data framework for connecting custom data sources to large language models."
                        type={"applied"}
                        label="Framework"
                        source="https://www.llamaindex.ai"
                    />
                    <ModelPost
                        id={1}
                        title="Llamaindex"
                        description="LlamaIndex is a simple, flexible data framework for connecting custom data sources to large language models."
                        type={"applied"}
                        label="Framework"
                        source="https://www.llamaindex.ai"
                    />
                </div>
            </div>    
            <div className="home__services">
                <h1>Services</h1>
                <div className="home__servicesRow">
                    <ServiceProduct
                        id={1}
                        title="Invest in a Vision"
                        description="Find the company you belive understand a real life problem and could use compute resources funded by you to achieve the distant goal"
                        button_text="Browse Developers"
                    />
                    <ServiceProduct
                        id={1}
                        title="Invest in a Vision"
                        description="Find the company you belive understand a real life problem and could use compute resources funded by you to achieve the distant goal"
                        button_text="Browse Developers"
                    />
                    <ServiceProduct
                        id={1}
                        title="Invest in a Vision"
                        description="Find the company you belive understand a real life problem and could use compute resources funded by you to achieve the distant goal"
                        button_text="Browse Developers"
                    />
                    <ServiceProduct
                        id={1}
                        title="Invest in a Vision"
                        description="Find the company you belive understand a real life problem and could use compute resources funded by you to achieve the distant goal"
                        button_text="Browse Developers"
                    />
                </div>
            </div>        
        </div>
    )
}

export default Home