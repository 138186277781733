import Airtable from 'airtable';
import React, { useState, useEffect } from 'react';

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

const Vision = () => {
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRecordId, setSelectedRecordId] = useState(null);

    useEffect(() => {
        base('projects').select({ view: "Grid view" }).eachPage((records, fetchNextPage) => {
            console.log(typeof records)
            setRecords(records);
            fetchNextPage();
        }, (err) => {
            if (err) {
                console.error(err);
                return;
            }
            setLoading(false);
        });
    }, []);

    const handleRecordClick = (recordId) => {
        setSelectedRecordId(selectedRecordId === recordId ? null : recordId);
    };

    const getRecordById = (recordId) => records.find(record => record.id === recordId);

    const renderOverlayAndCard = () => {
        const selectedRecord = getRecordById(selectedRecordId);
        if (!selectedRecord) return null;

        return (
            <>
                <div className="overlay" onClick={() => setSelectedRecordId(null)}></div>
                <div className="mini-card">
                    <h3>{selectedRecord.fields['name']}</h3>
                    <p>Label: {selectedRecord.fields['label']}</p>
                    <p>Description: {selectedRecord.fields['description']}</p>
                    {/* Add more fields as needed */}
                </div>
            </>
        );
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div>
            <h1>Trending Models</h1>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Label</th>
                            {/* Add more headers as needed */}
                        </tr>
                    </thead>
                    <tbody>
                        {records.map(record => (
                            <tr 
                              key={record.id} 
                              onClick={() => handleRecordClick(record.id)}
                              className={record.id === selectedRecordId ? 'selected' : ''}
                            >
                                <td className="clickable">{record.fields['name']}</td>
                                <td>{record.fields['label']}</td>
                                {/* Add more fields as needed */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {selectedRecordId && renderOverlayAndCard()}
        </div>
    );
};

export default Vision