import axios from 'axios';
import './Register.css'
import React, {useEffect, useState} from 'react';
import ModelArticle from "./ModelArticle"

const Register = () => {

    const [records, setRecords] = useState([]);
    
    let data = {
        "username": "arun",
        "password": "midmatching"
      };
      
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        baseURL: 'http://127.0.0.1:8000',
        url: '/aimodels',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token 6b43b86ac9c62a1298a8080e4633ec6b0f89d3bb'
        },
        data : data
    };

    useEffect(()=>{
        axios.request(config).then((response) => {
            setRecords(response.data.valueOf());
        }, (err) => {
            if (err) {
                console.error(err);
                return;
            }
        });
    },[])
    
    return (
        <div>
            <h1>Trending Models</h1>
            <div className="models__articleList">
                    {records.map(record => (
                        <ModelArticle 
                            key={record['id']} 
                            title={record['title']}
                            label={record['title']}
                        />
                    ))}
                </div>
        </div>
    );
};

export default Register