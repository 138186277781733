import React from 'react';
import Header from './Header';
import Home from './Home'
import Register from './Register'
import Models from './Models'
import Contact from './Contact'
import Vision from './Vision'
import Login from './Login'
import Footer from './Footer'
import LanguageModelForm from './LanguageModelForm'

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import './App.css'; // Make sure this path is correct for your CSS file

function App(){
    return (
        <Router>
        <div className='App'>
            <Header/>
            <Routes>
                <Route path="/"  element={<Home />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/Register"  element={<Register />} />
                <Route path="/Models"  element={<Models />} />
                <Route path="/Contact"  element={<Contact />} />
                <Route path="/Vision"  element={<Vision />} />
                <Route path="/LanguageModelForm"  element={<LanguageModelForm />} />
            </Routes>
            <Footer/>
        </div>
        </Router>
    );
}

export default App;
