import React from 'react';
import './ModelPost.css';

function ModelPost({id, title, description, type, label, source}){
    return(
        <div className='modelpost'>
            <div className='modelpost__info'>
                <h4>{title}</h4>
                <p>{description}</p>
                <p>{type}</p>
            </div>
        </div>
    )
}

export default ModelPost