import React from 'react';
import './Tags.css';

function Tags({tag_items, filter_records, set_records}){
    return(
        <>
        {tag_items.map(value => (
            <div className='tags'>
                <button className='tags__button' onClick={()=>filter_records(value)}>{value}</button>
            </div>
        ))}
        </>
    )
}

export default Tags