import React from "react";
import "./Login.css";

function Login(){
    return(
        <div className="login">
            <img className="login__logo" alt="company logo"/>
            <div className="login__container">
                <h1>Sign In</h1>
                <form>
                    <h5>E-mail</h5>
                    <input type="email"/>
                    <h5>Password</h5>
                    <input type="password"/>
                    <button type="submit" className="login__signInButton">Sign In</button>
                </form>
                <p>By signing-in, you agree to HalfSpin's Terms and Conditions</p>
                <button className="login__register">Create your HalfSpin Account</button>
            </div>
        </div>
    )
}

export default Login