import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer(){
    return (
        <div className="footer">
            <div className="footer__linksArea">
                <img className="footer__logoTag" alt="logo"/>
                <h2>The one-stop shop for LLM enthusiasts, investors, and businesses.</h2>
            </div>
            <div className="footer__linksArea">
                <Link to="/About" className="footer__pageLink">
                    <div className="footer__link">
                        <span>About Us</span>
                    </div>
                </Link>
                <Link to="/Models" className="footer__pageLink">
                    <div className="footer__link">
                        <span>Models</span>
                    </div>
                </Link>
                <Link to="/Register" className="footer__pageLink">
                    <div className="footer__link">
                        <span>Register</span>
                    </div>
                </Link>
                <Link to="/Jobs" className="footer__pageLink">
                    <div className="footer__link">
                        <span>Jobs</span>
                    </div>
                </Link>
                <Link to="/Contact" className="footer__pageLink">
                    <div className="footer__link">
                        <span>Contact Us</span>
                    </div>
                </Link>
            </div>
            <div className="footer__linksArea"/>
            <div className="footer__linksArea"/>
        </div>
    )
}

export default Footer