// LanguageModelForm.js

import axios from 'axios';
import React, { useState } from 'react';
import './LanguageModelForm.css';

const LanguageModelForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    benchmark_details: '',
    websiteLink: '',
    githubLink: '',
    huggingFaceLink: '',
    papers: '',
    category: '',
    tags: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if(formData.tags === ''){delete formData.tags;}
    if(formData.category === ''){delete formData.category;}
    if(formData.papers === ''){delete formData.papers;}

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://127.0.0.1:8000/aimodels/',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Token 6b43b86ac9c62a1298a8080e4633ec6b0f89d3bb'
        },
        data : formData
    };

    axios.request(config).then((response) => {
        console.log(JSON.stringify(response.data));
    }).catch((error) => {
        console.log(error);
    });

    // Reset the form after submission
    setFormData({
        title: '',
        description: '',
        benchmark_details: '',
        websiteLink: '',
        githubLink: '',
        huggingFaceLink: '',
        papers: '',
        category: '',
        tags: ''
    });
  };

  return (
    <div className="language-model-form">
        <h2>Register Language Model</h2>
        <form onSubmit={handleSubmit} className='lm__form'>
            <label className='lm__formLabel'>
                Title:
                <input className='lm__formText' type="text" name="title" value={formData.title} onChange={handleChange} required />
            </label>

            <label className='lm__formLabel'>
                Description:
                <textarea className='lm__formText' name="description" value={formData.description} onChange={handleChange} required />
            </label>

            <label className='lm__formLabel'>
                Benchmark Details:
                <textarea className='lm__formText' name="benchmark_details" value={formData.benchmark_details} onChange={handleChange} required />
            </label>

            <label className='lm__formLabel'>
                Website Link:
                <input className='lm__formText' type="url" name="websiteLink" value={formData.websiteLink} onChange={handleChange} required />
            </label>

            <label className='lm__formLabel'>
                GitHub Link:
                <input className='lm__formText' type="url" name="githubLink" value={formData.githubLink} onChange={handleChange} required />
            </label>

            <label className='lm__formLabel'>
                Hugging Face Link:
                <input className='lm__formText' type="url" name="huggingFaceLink" value={formData.huggingFaceLink} onChange={handleChange} required />
            </label>

            <label className='lm__formLabel'>
                Papers:
                <input className='lm__formText' type="text" name="papers" value={formData.papers} onChange={handleChange} />
            </label>

            <label className='lm__formLabel'>
                Category:
                <input className='lm__formText' type="text" name="category" value={formData.category} onChange={handleChange} />
            </label>

            <label className='lm__formLabel'>
                Tags:
                <input className='lm__formText' type="text" name="tags" value={formData.tags} onChange={handleChange} />
            </label>

            <button className='lm__formButton' type="submit">Register Model</button>
        </form>
    </div>
  );
};

export default LanguageModelForm;
