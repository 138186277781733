import React from 'react';
import './ServiceProduct.css';

function ServiceProduct({id, title, description, button_text}){
    return(
        <div className='serviceproduct'>
            <div className='serviceproduct__info'>
                <h4>{title}</h4>
                <p>{description}</p>
                <button>{button_text}</button>
            </div>
        </div>
    )
}

export default ServiceProduct