import React from 'react';
import './ModelArticle.css';

function ModelArticle({key, title, label}){
    return(
        <div className='model__article'>
            <div className='model__articleInfo'>
                <h4>{title}</h4>
                <span>{label}</span>
            </div>
        </div>
    )
}

export default ModelArticle